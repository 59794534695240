<script lang="ts">
    export let username: string;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="UserTag">
    <span>{username}</span>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="UserTag"] {
        background-color: $primary;
        color: white;
        font-size: 0.8rem;
        padding: 0.3rem 0.5rem;
        border-radius: 0.5rem;
        width: min-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
