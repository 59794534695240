<script lang="ts">
  export let color = "currentColor";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AudioAnimation" style={`--color:${color};`}>
  <div class="icon">
    <span />
    <span />
    <span />
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="AudioAnimation"] {
    height: fit-content;
    width: fit-content;
    border-radius: 0.5em;
  }

  $bar-color: var(--color);
  $bar-width: 0.5em;
  $bar-height: 0.5em;
  $bar-spacing: 0.1em;

  .icon {
    display: flex;
    justify-content: space-between;
    gap: $bar-spacing;
    height: $bar-height;
    width: fit-content;

    span {
      width: $bar-width;
      height: 100%;
      background-color: $bar-color;
      border-radius: 0.5em;
      box-shadow: 0 0 0.5em 0.05em white;
      animation: loading 1s ease infinite;
      content: "";

      &:nth-of-type(2) {
        animation-delay: 0.2s; /* Start at the end of animation */
      }

      &:nth-of-type(3) {
        animation-delay: 0.4s; /* Start mid-way of return of animation */
      }
    }
  }

  @keyframes loading {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
