<script lang="ts">
  import QRCode from "qrcode";

  export let url: string;
  export let size: string = undefined;

  let qrCodeImageSource = "";
  $: updateQr(url);

  const updateQr = async (url: string) => {
    qrCodeImageSource = "";
    if (url) {
      qrCodeImageSource = await QRCode.toDataURL(url);
    }
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="qrcode">
  {#if qrCodeImageSource}
    <img
      style={size ? `height:${size}` : undefined}
      src={qrCodeImageSource}
      alt="qrcode"
    />
  {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
</style>
